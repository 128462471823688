.dropdown {
  &_content {
    position: absolute;
    top:calc(100% + 15px);
    right: 0;
    min-width: 150px;
    overflow: hidden;
    z-index: $zindex-dropdown;

    &.select-member {
      top: 100%;
      .dropdown {
        &__item {
          &:not(:last-child) {
            padding-top: 16px;
            padding-bottom: 20px;
          }
          border-top: unset;
          padding-top: 0;
          padding-bottom: 16px;
          padding-left: 16px;
          padding-right: 16px;
          font-size: 14px;
          &:hover {
            color: $highlighted;
          }        
        }        
      }
    }
    
  }
  &_toggle {
    &.current {
      path {
        fill: $highlighted;
      }
      .icon-transformation {
        svg {
          transform: rotate(180deg);
          transition: all 0.5s ease-in-out;
        }
        path {
          fill: $white;
        }
      }
      &.icon {
        &__secondary {
          path {
            fill: $secondary;
          }
        }
        &__white {
          path {
            fill: $white;
          }
        }
      }
      &.member__toggle {
        path {
          fill: $secondary
        }
      }  
    }
    &.icon {
      &__secondary {
        path {
          fill: $secondary;
        }
      }
      &__white {
        path {
          fill: $white;
        }
      }
    }
  }
  &_content {
    &__large {
      min-width: 664px;
    }
    &__smaller {
      width: 100%;
      min-width: 539px;
      top: calc(100% + 23px);
      @media screen and (min-width: 567px) and (max-width: 700px) {
        width: calc(100vw - 316px);
        max-width: 100vw;
        min-width: unset;
      }
      @media screen and (max-width: 566px) {
        min-width: calc(100vw - 40px);
      }
    }
    &__extrasmall {
      overflow: visible;
      min-width: 180px;
      top: calc(100% + 5px);
      right: unset;
    }
    &__xxxl {
      min-width: 90px;
      right: -20px;
      top: calc(100% + 9px);
      &__top {
        top: calc(100% - 105px);
      }
      &.arrow-up {
        overflow: visible;
        &::before {
          content: '';
          position: absolute;
          right: 27px;
          top: -6px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid $disabled;
        }
        &::after {
          content: '';
          position: absolute;
          right: 27px;
          top: -4.5px;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 6px solid $white;
        }
        &__rotate {
          &::before {
            bottom: -7px;
            top: revert;
            transform: rotate(180deg);
          }
          &::after {
            bottom: -5.5px;
            top: revert;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

.dropdown {
  &__item {
    &:hover {
      color: $highlighted;
      svg {
        path {
          fill: $highlighted;
        }
      }
      .submenu {
        &__list {
          display: block;
          color: initial;
        }
      }
    }
    &:first-child {
      padding-top: 20px;
    }
    &:last-child {
      border-top: 1px solid $separator;
      padding-top: 20px;
    }
  }
}

.submenu {
  &__list {
    display: none;
    left: 100%;
    top: -1px;
    z-index: 1;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    transition: all 0.5s ease-in-out;
    @media screen and (min-width: 576.5px) and (max-width: 991.5px) {
      left: -155px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
  }
  &__item {
    &:hover {
      color: $highlighted;
    }
    &:not(:first-child) {
      padding-bottom: 20px;
    }
    &:not(:only-child):last-child {
      border-top: 1px solid $separator;
    }
    &:only-child {
      position: absolute;
      top: -21px;
      padding-bottom: 20px;
      padding-top: 20px;
      background-color: $white;
      border: 1px solid $separator;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      margin-left: -1px;
    }
    @media screen and (min-width: 576.5px) and (max-width: 991.5px) {
      &:only-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 0;
        border-top-left-radius: 8px;
        border-top-right-radius: 0;
        margin-left: -2px;
      }
    }
  }
}

.arrow-up {
  overflow: visible;
  &::after {
    content: '';
    position: absolute;
    top: -7px;
    right: 14px;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 7px solid $white;
  }
}

.contactCompany-filter, .request-filter, .archive-filter {
  &__with-chat, &__without-chat {
    max-width: 539px;
    min-width: unset;
    width: 100vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1380px) {
  .dropdown {
    &_content {
      &__large {
        &__with-chat {
          min-width: calc(100vw - 700px);
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) {
  .contactCompany-filter {
    &__with-chat {
      width: calc(100vw - 907px);
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .contactCompany-filter {
    &__with-chat {
      width: 100vw;
      max-width: calc(100vw - 779px);
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1120px) {
  .archive-filter {
    &__with-chat {
      width: calc(100vw - 579px);
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1090px) {
  .contactCompany-filter {
    &__without-chat {
      width: calc(100vw - 552px);
      max-width: 539px;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .contactCompany-filter {
    &__with-chat {
      width: 100vw;
      max-width: calc(100vw - 671px);
    }
  }
  .dropdown {
    &_content {
      &__large {
        &__with-chat {
          min-width: calc(100vw - 592px);
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1010px) {
  .archive-filter {
    &__with-chat {
      width: calc(100vw - 471px);
    }
  }
}

@media screen and (max-width: 935px) {
  .request-filter {
    &__with-chat {
      width: calc(100vw - 395px);
    }    
  }
}

@media screen and (max-width: 991px) {
  .input {
    &__mobile {
      width: 100%;
    }
  }
  .contactCompany-filter {
    &__with-chat {
      width: 100vw;
      max-width: calc(100vw - 395px);
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 872px) {
  .contactCompany-filter {
    &__without-chat {
      max-width: calc(100vw - 316px);
    }
  }
}

@media screen and (max-width: 768px) {
  .contactCompany-filter {
    &__without-chat {
      max-width: calc(100vw - 40px);
    }
  }
}

@media screen and (max-width: 655px) {
  .archive-filter {
    &__without-chat {
      width: calc(100vw - 116px);
    }
  }
}

@media screen and (max-width: 576px) {
  .request-filter {
    &__without-chat {
      width: calc(100vw - 40px);
    }
  }
}

@media screen and (max-width: 374px) {
  .sorting-filter {
    margin-top: 10px;
    width: 100%;
  }
}
