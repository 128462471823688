.spinner,
.spinner:after {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.spinner__s, .spinner__s:after {
  width: 15px;
  height: 15px;
}
.spinner__m, .spinner__m:after {
  width: 18px;
  height: 18px;
}
.spinner {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // margin: -15px 0 0 -15px;
  border: 3px solid $white;
  border-left-color: $hidden;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  &__s {
    border-width: 2px;
  }
  &__success {
    border-color: $success;
    border-left-color: $hidden;
  }
  &__secondary{
    border-color: $secondary;
    border-left-color: $hidden;
  }
  &__highlighted{
    border-color: $highlighted;
    border-left-color: $hidden;
  }
  &__rose {
    border-color: $rose;
    border-left-color: $hidden;
  }
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.progress {
  background-color: transparentize($color: $brand, $amount: .8);
  height: 2px;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 15;

  &::before,
  &::after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    position: absolute;
    transition: transform 0.2s linear;
    transform-origin: left;
    //background-color: theme-color("primary");
    background-color: $brand;
  }

  &::before {
    width: auto;
    animation: progressBar1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }

  &::after {
    width: auto;
    animation: progressBar2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    animation-delay: 1.15s;
  }
}

@keyframes progressBar1 {
  //  |-----|---x-||-----||-----|
  0% {
    left: -35%;
    right: 100%;
  }
  //  |-----|-----||-----||xxxx-|
  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes progressBar2 {
  //  |xxxxx|xxxxx||-----||-----|
  0% {
    left: -200%;
    right: 100%;
  }
  //  |-----|-----||-----||-x----|
  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}