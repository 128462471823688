.scrollbar {
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  -webkit-overflow-scrolling: touch;
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 4px; /* Remove scrollbar space */
    height: 4px;
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  /* Optional: show position indicator in red */
  &::-webkit-scrollbar-thumb {
    background-color: transparent;

    @include transition(background-color 200ms);

    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-border-radius: 5px;
    border-radius: 5px;
  }

  .no-touch &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: $secondary;
    }

    //&::-webkit-scrollbar {
    //display: block;
    //}
  }

  &__highlighted {
    padding-right: 10px;
    &::-webkit-scrollbar {
      width: 4px;
      background-color: $separator;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: $separator;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $highlighted;
      border-radius: 6px;
    }
  }
  &__hidden {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@media screen and (max-width: 812px) {
  .scrollbar {
    padding-right: 0;
    &__highlighted {
      padding-right: 0;
    }
  }
}
