@import "~scss/functions";
@import "~scss/variables";
@import "~scss/mixins";

.custom-modal {
  background: rgba(15, 29, 70, 0.63);
  z-index: 1036;
  top: 0;
  left: 0;
  padding-top: 75px;
  padding-bottom: 20px;
  /* Modal content */
  &_content {
    min-height: 538px;
    max-width: 668px;
    border-radius: 22px;
    .close {
      right: 20px;
      top: 20px;
    }
    &__credit-card {
      min-height: 320px;
    }
    @media screen and (max-width: 708px) {
      max-width: calc(100% - 40px);
    }
  }
  &_height {
    min-height: 372px;
  }
}


.popup {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1036;
  background: rgba(15, 29, 70, 0.63);

  &_container {
    left: 50%;
    top: 50%;
  }
}

.popup-btn {
  &__width {
    width: 252px;
  }
}

.close {
  right: 21px;
  top: 21px;
}

.select-top-auto {
  .select_menu__top {
    top: auto;
    bottom: 70px;
  }
}