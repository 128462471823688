// stylelint-disable declaration-no-important

$positions: static, relative, absolute, fixed, sticky !default;

// Common values
@each $position in $positions {
  .position-#{$position} { position: $position; }
}

// Shorthand

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.sticky-top {
  @supports (position: sticky) {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
  }
}
