// stylelint-disable declaration-no-important

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    33: 33.33333%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Width and height
@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length; }
  }
}

.mw-100 { max-width: 100%; }
.mh-100 { max-height: 100%; }

// Viewport additional helpers

.min-vw-100 { min-width: 100vw; }
.min-vh-100 { min-height: 100vh; }

.max-vh-100 { max-height: 100vh; }

.vw-100 { width: 100vw; }
.vh-100 { height: 100vh; }

.fit {
  &-under-logo {
    min-height: calc(100vh - 228px); // 64 + 64 + 58 + 42
  }
}
.box {
  &-32 {
    width: 32px;
    height: 32px;
  }
  &-40 {
    width: 40px;
    height: 40px;
  }
  &-136 {
    width: 136px;
    height: 136px;
  }
}

.w-300 {
  max-width: 300px;
}

.min-w-315 {
  min-width: 315px;
}
.max-w-315 {
  max-width: 315px;
  @media screen and (max-width: 767.5px) {
    max-width: revert;
  }
}
.w-676 { // 320 * 2 + 36 margin
  width: 676px;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .input {
    &_min-height {
      .input {
        min-height: 100px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .mw-md-100 {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .mw-sm-100 {
    width: 100%;
  }
}

@media screen and (max-width: 567px) {
  .input {
    &_min-height {
      .input {
        min-height: 100px;
      }
    }
  }
}