.logo {
  width: 246px;
}

.object-fit {
  object-fit: scale-down;
}

.company {
  &__avatar {
    object-fit: scale-down;
    padding: 3px;
    &__width {
      min-width: 52px;
    }
  }
}

.contact {
  &__avatar {
    object-fit: cover;
    min-width: 52px;
    &__sm {
      min-width: 37px;
    }
  }

}

.has-notification {
  top: 16px;
  right: 0;
  width: 6px;
  height: 6px;
}

.z-index-1025 {
  z-index: 1025;
}
.z-index-1030 {
  z-index: 1030;
}

@media (max-width: 575px) {
  .logo {
    width: 146px;
    height: 36px;
  }
}

@media screen and (max-width: 768px) {
  // TODO when create mabnagement component and her scss file move to there
  .icon {
    &__mobile {
      width: 24px;
      height: 24px;
    }
  }
}
