.tabs {
  &_toggle {
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 10px;
  }
  &__bottom-transparent {
    margin-bottom: -1px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {   
  .tab-overflow {
    &__tablet {
      overflow: auto hidden;
      max-width: 802px;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 767px) {  
  .tabs-overflow {
    &__scrolling {    
      overflow: auto hidden;
      width: calc(100vw - 10px);
      &::-webkit-scrollbar {
        display: none;
      }
    }    
  }  
}
