// Responsive images (ensure images don't scale beyond their parents)
.img {
  &-fill {
    width: 100%;
    height: auto;
  }

  &-fit {
    width: auto;
    height: 100%;
  }

  &-fluid {
    @include img-fluid();
  }

  &-fillfit {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

