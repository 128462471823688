.show-more {
  bottom: 2px;
  right: 0;
}

.fade {
  position: relative;

  &__white:after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 98%);
  }

  &__light:after {
    background: linear-gradient(to right, rgba(246, 249, 255, 0), rgba(246, 249, 255, 1) 98%);
  }

  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80%;
    height: 24px;


  }
}


.showMoreLess {
  &_collapse {
    width: calc(100% - 80px);
    display: inline-block;
    vertical-align: top;

    &.noShowMore {
      width: auto;
    }
  }

  &_trigger {
    display: inline-block;
    width: 75px;
  }
}