.default-placeholder {
  background-color: #F6F9FF !important;
  border: none !important;
  padding: 12px 20px !important;
  height: 44px !important;
  box-shadow: none !important;
  border-radius: 12px !important;
  color: #2D3037 !important;

  & .text {
    font-size: 16px !important;
    font-family: inherit;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;

    & .dates-container {
      font-size: 100% !important;
    }
  }

  & .icon, & .calendar-hooks .hook {
    border-color: #8C93A6 !important;
  }

  & .date-dots .dot {
    background-color: #8C93A6 !important;
  }
}
#__range-picker-container .full-date-picker-container {
  box-shadow: 4px 4px 17px rgba(24, 41, 92, 0.22) !important;
  padding: 10px 10px 24px 10px !important;
  border-radius: 8px !important;
}