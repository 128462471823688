.aside {
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
  width: 436px;
  right: -436px;
  position: relative;
  overflow-y: auto;
  @include transition(right 200ms);
  &_backdrop {
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    @include transition(background-color 200ms);
    z-index: -1;
  }
  &_close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &_content {
    height: calc(100% - 216px);
    overflow: auto;
  }
  &__open {
    background-color: transparentize($color: $primary, $amount: 0.1);
    z-index: $zindex-modal-backdrop;
    .aside {
      right: 0;
    }
  }
}