.auth {
  &_copyright {
    position: absolute;
    bottom: 46px;
    right: 46px;
  }
}

.expired_content {
  margin-top: 90px;
}

.layout {
  &-main {
    padding-top: 75px;
    padding-left: 108px;
    &__75 {
      padding-top: 75px;
    }
    &__130 {
      padding-top: 130px;
      @media screen and (max-width: 992px) {
        padding-top: 75px;
      }
    }
  }

  &-middle {
    width: 100%;
    padding-right: 20px;
    transition: width 200ms;
  }

  &__with-aside {
    width: calc(100% - 355px);
  }
}

@media (max-width: 1024px) {
  .layout {
    &-main {
      padding-left: 10px;
    }
    &-middle {
      padding-right: 10px;
    }
  }
}

@media (max-width: 767px) {
  .layout {
    &__with-aside {
      width: 100%;
      padding-top: 20px;
    }
  }
}

@media (max-width: 575px) {
  .auth {
    &_copyright {
      bottom: 46px;
      right: 0;
      left: 0;
      text-align: center;
    }
  }
  .expired_content {
    margin-top: 46px;
  }
}
