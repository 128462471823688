// stylelint-disable declaration-no-important

//@each $color, $value in $theme-colors {
//  // @include bg-variant(".bg-#{$color}", $value, true);
//  .bg-#{$color} {
//    background-color: $value;
//  }
//}
//
//@media screen and (max-width: 575px) {
//  .bg-white-xs {
//    background-color: $white;
//  }
//}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $key, $value in $theme-colors {
      .bg#{$infix}-#{$key} {
        background-color: $value;
        @include transition(background-color 200ms);
      }
    }
  }
}