@import "~scss/functions";
@import "~scss/variables";
@import "~scss/mixins";

$tooltip-max-width: 360px;

// opend to top
.customer-tooltip {
  position: absolute;
  overflow: hidden;
  max-width: $tooltip-max-width;
  z-index: 1;
  opacity: 0;

  &__direction {
    &__top {
      bottom: 0;
      transform: translateY(-100%);
    }

    &__bottom {
      bottom: -40px;
    }
  }
}

.customer__link:hover + .customer-tooltip {
  overflow: visible;
  opacity: 1;
}

.tooltip-arrow {
  position: absolute;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid $brand;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
}

// opend to bottom
.customer-tooltip__direction__bottom .tooltip-arrow {
  bottom: 30px;
  transform: translateX(-50%) rotate(180deg);
}

.__react_component_tooltip.custom {
  border-radius: 8px;
  font-size: 12px;
  padding: 5px 10px;
}
.__react_component_tooltip.place-bottom::before {
  left: calc(50% - 0.5px) !important;
}
