.StripeElement {
  width: 100%;
  overflow: hidden;
  border: none;
  @include transition(padding 200ms);
  outline: 0;
  min-height: 56px;
  padding: 16px;
  background-color: $light;
  border-radius: 12px;

  &--focus {
    & + .input_label {
      color: $highlighted;

      &.error {
        color: $danger;
      }
    }

    & ~ .input_border {
      transform: scale(1, 1);
    }
  }
}