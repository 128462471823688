// stylelint-disable declaration-no-important

//
// Visibility utilities
//

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}
