.custom-table {
  &_row {
    display: flex;
  }
  &_cell {
    vertical-align: middle;
    display: table-cell;
  }
  &__wrapped {
    padding: 0 10px;
    font-size: 14px;
    &:last-child {
      padding-right: 0;
    }
  }
  &_cell + &_cell:not(:last-child) {
    padding: 0 20px;
  }
  &__wrapped + &__wrapped:not(:last-child) {
    padding: 0 10px;
    font-size: 14px;
  }
}

@media (max-width: 991.5px) {
  .custom-table {
    &_row {
      flex-direction: column;
      width: 100%;
    }

    &_cell {
      display: block;
      min-width: 100%;
    }

    &_cell + &_cell:not(:last-child) {
      padding: 0;
      margin-top: 16px;
      &.border-top {
        &__dashed {
          border-top: 1px dashed $separator;
          padding-top: 16px;
        }
      }
    }
    &_cell:nth-last-of-type(1) {
      margin-top: 0;
    }
  }
}